
(function($) {
	'use strict';

	jQuery(document).ready(function($) {
		$.ajax({
			url: 'https://api.instagram.com/v1/users/self/media/recent',
			type: 'GET',
			dataType: 'jsonp',
			contentType: 'application/json',
			cache: false,
			data: {
              access_token: '4719299373.1677ed0.55f23c133a774e0b9d235bf46ccfb65f'
			},
			success: function(responsive) {
				var is_json = false;
				try {
					if (responsive.meta.code == 200)
						is_json = true;
				} catch (ex) {
					is_json = false;
				}
				if (is_json) {
				  console.log(responsive);
                  var html = '';
                  
                  var data = responsive.data;
                  $.each(data,function(index,element){
                    if(index >= 8 ) return 0;
                  	html += '<li><a href="'+element.link+'"><img src="'+element.images.thumbnail.url+'" alt=""></a><div class="int-overlay"><a href="'+element.link+'" class="center-icon"><i class="i-instagram3"></i></a></li>';
                  
                  });
                  $(".insta-list-image").html(html);
                  
				}
			}
		});
	});
              
})(jQuery)